import React from 'react';
import {
  Box,
  Text,
  useColorModeValue,
  List,
  Stack,
  StackDivider
} from '@chakra-ui/react';
import { InformationListItem } from '../components/DataPresentation';
import useAxios from '../utils/useAxios';
import { useEffect, useState } from 'react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
const OpsDashboard = () => {
  // Colors
  const secondaryColor = useColorModeValue('gray.900', 'yellow.100');
  const subHeaderColor = useColorModeValue('gray.500', 'gray.400');
  const stackDividerColor = useColorModeValue('gray.200', 'gray.600');

  const [statsData, setStatsData] = useState<any>({});

  const api = useAxios();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/ops/dashboard');
        setStatsData(response.data);
      } catch (e) {
        console.log('Error: ', e);
        setStatsData({});
      }
    };
    fetchData();
    const id = setInterval(() => fetchData(), 300000);
    return () => clearInterval(id);
  }, []);

  return (
    <Box margin={'5'}>
      <ColorModeSwitcher />
      <Stack
        spacing={{ base: 4, sm: 2 }}
        direction={'column'}
        divider={<StackDivider borderColor={stackDividerColor} />}
      >
        <Text
          color={secondaryColor}
          fontSize={'5xl'}
          fontWeight={'300'}
          textTransform={'uppercase'}
          mb={'1'}
        >
          Statistics
        </Text>
        <Box>
          <Text
            color={secondaryColor}
            fontSize={'4xl'}
            fontWeight={'500'}
            textTransform={'uppercase'}
            mb={'4'}
          >
            All events
          </Text>
          <Stack
            spacing={{ base: 4, sm: 10 }}
            direction={'row'}
            divider={<StackDivider borderColor={stackDividerColor} />}
          >
            <Box>
              <Text
                color={secondaryColor}
                fontSize={'2xl'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}
              >
                Last hour
              </Text>
              <List spacing={10}>
                {statsData && statsData.last_day_all ? (
                  <List spacing={2}>
                    <InformationListItem
                      label="2 secs"
                      value={statsData.last_day_all[0].c2_1h}
                      fontSize={'xl'}
                    />
                    <InformationListItem
                      label="5 secs"
                      value={statsData.last_day_all[0].c5_1h}
                      fontSize={'xl'}
                    />
                  </List>
                ) : (
                  <div>No data!</div>
                )}
              </List>
            </Box>

            <Box>
              <Text
                color={secondaryColor}
                fontSize={'2xl'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}
              >
                Last 4 hours
              </Text>
              <List spacing={10}>
                {statsData && statsData.last_day_all ? (
                  <List spacing={2}>
                    <InformationListItem
                      label="2 secs"
                      value={statsData.last_day_all[0].c2_4h}
                      fontSize={'xl'}
                    />
                    <InformationListItem
                      label="5 secs"
                      value={statsData.last_day_all[0].c5_4h}
                      fontSize={'xl'}
                    />
                  </List>
                ) : (
                  <div>No data!</div>
                )}
              </List>
            </Box>
            <Box>
              <Text
                color={secondaryColor}
                fontSize={'2xl'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}
              >
                Last 8 hours
              </Text>
              <List spacing={10}>
                {statsData && statsData.last_day_all ? (
                  <List spacing={2}>
                    <InformationListItem
                      label="2 secs"
                      value={statsData.last_day_all[0].c2_8h}
                      fontSize={'xl'}
                    />
                    <InformationListItem
                      label="5 secs"
                      value={statsData.last_day_all[0].c5_8h}
                      fontSize={'xl'}
                    />
                  </List>
                ) : (
                  <div>No data!</div>
                )}
              </List>
            </Box>
            <Box>
              <Text
                color={secondaryColor}
                fontSize={'2xl'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}
              >
                Last 24 hours
              </Text>
              <List spacing={10}>
                {statsData && statsData.last_day_all ? (
                  <List spacing={2}>
                    <InformationListItem
                      label="2 secs"
                      value={statsData.last_day_all[0].c2_24h}
                      fontSize={'xl'}
                    />
                    <InformationListItem
                      label="5 secs"
                      value={statsData.last_day_all[0].c5_24h}
                      fontSize={'xl'}
                    />
                  </List>
                ) : (
                  <div>No data!</div>
                )}
              </List>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Text
            color={secondaryColor}
            fontSize={'4xl'}
            fontWeight={'500'}
            textTransform={'uppercase'}
            mb={'4'}
          >
            Label printing events
          </Text>
          <Stack
            spacing={{ base: 4, sm: 10 }}
            direction={'row'}
            divider={<StackDivider borderColor={stackDividerColor} />}
          >
            <Box>
              <Text
                color={secondaryColor}
                fontSize={'2xl'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}
              >
                Last hour
              </Text>
              <List spacing={10}>
                {statsData && statsData.last_day_label_prints ? (
                  <List spacing={2}>
                    <InformationListItem
                      label="2 secs"
                      value={statsData.last_day_label_prints[0].c2_1h}
                      fontSize={'xl'}
                    />
                    <InformationListItem
                      label="5 secs"
                      value={statsData.last_day_label_prints[0].c5_1h}
                      fontSize={'xl'}
                    />
                  </List>
                ) : (
                  <div>No data!</div>
                )}
              </List>
            </Box>

            <Box>
              <Text
                color={secondaryColor}
                fontSize={'2xl'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}
              >
                Last 4 hours
              </Text>
              <List spacing={10}>
                {statsData && statsData.last_day_label_prints ? (
                  <List spacing={2}>
                    <InformationListItem
                      label="2 secs"
                      value={statsData.last_day_label_prints[0].c2_4h}
                      fontSize={'xl'}
                    />
                    <InformationListItem
                      label="5 secs"
                      value={statsData.last_day_label_prints[0].c5_4h}
                      fontSize={'xl'}
                    />
                  </List>
                ) : (
                  <div>No data!</div>
                )}
              </List>
            </Box>
            <Box>
              <Text
                color={secondaryColor}
                fontSize={'2xl'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}
              >
                Last 8 hours
              </Text>
              <List spacing={10}>
                {statsData && statsData.last_day_label_prints ? (
                  <List spacing={2}>
                    <InformationListItem
                      label="2 secs"
                      value={statsData.last_day_label_prints[0].c2_8h}
                      fontSize={'xl'}
                    />
                    <InformationListItem
                      label="5 secs"
                      value={statsData.last_day_label_prints[0].c5_8h}
                      fontSize={'xl'}
                    />
                  </List>
                ) : (
                  <div>No data!</div>
                )}
              </List>
            </Box>
            <Box>
              <Text
                color={secondaryColor}
                fontSize={'2xl'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}
              >
                Last 24 hours
              </Text>
              <List spacing={10}>
                {statsData && statsData.last_day_label_prints ? (
                  <List spacing={2}>
                    <InformationListItem
                      label="2 secs"
                      value={statsData.last_day_label_prints[0].c2_24h}
                      fontSize={'xl'}
                    />
                    <InformationListItem
                      label="5 secs"
                      value={statsData.last_day_label_prints[0].c5_24h}
                      fontSize={'xl'}
                    />
                  </List>
                ) : (
                  <div>No data!</div>
                )}
              </List>
            </Box>
          </Stack>
          <List spacing={10} marginTop={10}>
            {statsData && statsData.print_events ? (
              <List spacing={2}>
                {statsData.print_events?.map((event: any) => (
                  <Text fontSize={'medium'} fontWeight={'300'} mb={'4'}>
                    {new Date(event.when).toLocaleString('nb-no')} |{' '}
                    {event.time.toFixed(2)}s | {event.order} | {event.user}
                  </Text>
                ))}
              </List>
            ) : (
              <div>No data!</div>
            )}
          </List>
        </Box>
      </Stack>
    </Box>
  );
};

export default OpsDashboard;
