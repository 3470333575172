import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAxios from '../utils/useAxios';

const AppContext = createContext();

export default AppContext;

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const axios = useAxios();

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await axios.get('/users/me');
        setUser(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getUser();
  }, []);

  const contextData = {
    user
  };

  return (
    <AppContext.Provider value={contextData}>
      {loading ? null : children}
    </AppContext.Provider>
  );
};
