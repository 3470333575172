import style from './PicksDashboard.module.css';
import {
  Text,
  Box,
  useColorMode,
  Heading,
  SimpleGrid,
  Flex
} from '@chakra-ui/react';
import useAxios from '../utils/useAxios';
import { useEffect, useState } from 'react';
import { FaClock, FaHand } from 'react-icons/fa6';
import { useSearchParams } from 'react-router-dom';

const getBarColor = (speed: number) => {
  if (speed < 60) {
    return '#EA0A33';
  }
  if (speed < 130) {
    return '#EDFAA3';
  }
  return '#3FD993';
};

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
const PicksDashboard = () => {
  const [statsData, setStatsData] = useState<any>({});
  const [scores, setScores] = useState([]);
  const { setColorMode } = useColorMode();
  const [searchParams, setSearchParams] = useSearchParams();

  const api = useAxios();
  useEffect(() => {
    setColorMode('dark');
    const fetchData = async () => {
      var filter = searchParams.get('org')
        ? '?org=' + searchParams.get('org')
        : '';
      try {
        const response = await api.get('/ops/picks' + filter);
        setStatsData(response.data);

        const score = await api.get('/ops/scoreboard' + filter);
        setScores(score.data.scores);
      } catch (e) {
        console.log('Error: ', e);
        setStatsData({});
        setScores([]);
      }
    };
    fetchData();
    const id = setInterval(() => fetchData(), 30000);
    return () => clearInterval(id);
  }, []);

  return (
    <SimpleGrid className={style.panels}>
      <SimpleGrid gap="0.25rem">
        <SimpleGrid
          className={style.panel}
          placeContent={'center'}
          pos={'relative'}
        >
          <Heading
            color={'zestBright.200'}
            fontSize={'3xl'}
            fontWeight={'400'}
            textTransform={'uppercase'}
            letterSpacing={'-0.04em'}
            lineHeight={1}
            pos={'absolute'}
            top={4}
            left={4}
          >
            Picks today
          </Heading>
          {statsData && statsData.picks_total ? (
            <Text
              color="white"
              fontSize={'10rem'}
              fontFamily={'Akkurat Mono'}
              fontWeight={'400'}
              textTransform={'uppercase'}
              align={'center'}
              lineHeight={1}
              style={{
                wordSpacing: '-0.25ch',
                fontSize: 'clamp(4rem, -0.6084rem + 8.1928vi, 12.5rem)'
              }}
            >
              {statsData.picks_today}
            </Text>
          ) : (
            <div></div>
          )}
        </SimpleGrid>
        <SimpleGrid
          className={style.panel}
          placeContent={'center'}
          position={'relative'}
        >
          <Heading
            color={'zestBright.200'}
            fontSize={'3xl'}
            fontWeight={'400'}
            textTransform={'uppercase'}
            letterSpacing={'-0.04em'}
            lineHeight={1}
            pos={'absolute'}
            top={4}
            left={4}
          >
            All-time picks
          </Heading>
          {statsData && statsData.picks_total ? (
            <Text
              color="white"
              fontSize={'10rem'}
              fontWeight={'400'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              align={'center'}
              lineHeight={1}
              style={{
                wordSpacing: '-0.25ch',
                fontSize: 'clamp(4rem, -0.6084rem + 8.1928vi, 12.5rem)'
              }}
            >
              {statsData.picks_total.toLocaleString('nb-NO')}
            </Text>
          ) : (
            ''
          )}
          {statsData && statsData.picks_total ? (
            <Text
              color={'gray.600'}
              fontSize={'sm'}
              fontWeight={'400'}
              lineHeight={1}
              position={'absolute'}
              bottom={4}
              left={0}
              right={0}
              align={'center'}
            >
              For Johan: {statsData.picks_total.toExponential()}
            </Text>
          ) : (
            ''
          )}
        </SimpleGrid>
      </SimpleGrid>

      <SimpleGrid gap={'0.25rem'} gridTemplateRows={'repeat(10, 1fr)'}>
        {scores.slice(0, 10).map((score, i) => (
          <Flex gap={'0.25rem'} alignItems={'stretch'} key={`score-${i}`}>
            <SimpleGrid
              className={style.panel}
              placeContent={'center'}
              fontSize={'5xl'}
              fontFamily={'Akkurat Mono'}
              lineHeight={1}
              minWidth={'2em'}
            >
              {Math.floor(score['speed']) >= 200 ? '👑' : i + 1}
            </SimpleGrid>
            <SimpleGrid
              className={style.panel}
              flexGrow={1}
              gap={2}
              placeContent={'center stretch'}
            >
              <Flex
                justify="space-between"
                alignItems={'center'}
                gap={'0.5rem'}
                mb={'0.25rem'}
              >
                <Flex flexGrow={1} alignItems={'center'} gap="0.5rem">
                  <Text
                    color="white"
                    fontSize={'2xl'}
                    fontWeight={'700'}
                    textTransform={'uppercase'}
                    letterSpacing={'-0.02em'}
                    lineHeight={1}
                  >
                    {score['username']}
                  </Text>
                  <Text
                    flexGrow={1}
                    color="gray.500"
                    fontSize={'xl'}
                    fontWeight={'500'}
                    letterSpacing={'-0.02em'}
                    lineHeight={1}
                  >
                    {score['org']}
                  </Text>
                  <Flex gap={4}>
                    <Flex gap={'0.375rem'} alignItems={'center'}>
                      <FaHand size={16} color="#D3D7DC" />
                      <Text
                        color={'gray.300'}
                        fontSize={'xl'}
                        lineHeight={1}
                        fontFamily={'Akkurat Mono'}
                      >
                        {score['tasks'] ?? 0}
                      </Text>
                    </Flex>
                    <Flex gap={'0.375rem'} alignItems={'center'}>
                      <FaClock size={16} color="#D3D7DC" />
                      <Text
                        color={'gray.300'}
                        fontSize={'xl'}
                        lineHeight={1}
                        fontFamily={'Akkurat Mono'}
                      >
                        {score['time'] >= 61 ? (
                          <Text as="span">
                            {Math.floor(score['time'] / 60)}
                            <Text as="span" fontFamily={'Akkurat'}>
                              h{' '}
                            </Text>
                            {Math.floor((score['time'] ?? 0) % 60)}
                            <Text as="span" fontFamily={'Akkurat'}>
                              m
                            </Text>
                          </Text>
                        ) : Math.floor(score['time']) === 60 ? (
                          <Text as="span">
                            1
                            <Text as="span" fontFamily={'Akkurat'}>
                              h
                            </Text>
                          </Text>
                        ) : (
                          <Text as="span">
                            {Math.floor((score['time'] ?? 0) % 60)}
                            <Text as="span" fontFamily={'Akkurat'}>
                              m
                            </Text>
                          </Text>
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Box
                className={`${style.scoremeter} ${score['speed'] > 199 ? style.godlike : ''}`}
                style={
                  {
                    '--width': `${Math.floor((score['speed'] * 100) / scores[0]['speed'])}%`,
                    '--color': getBarColor(score['speed'])
                  } as React.CSSProperties
                }
              />
            </SimpleGrid>
            <SimpleGrid className={style.panel} placeContent={'center'}>
              <Text
                fontSize={'5xl'}
                fontFamily={'Akkurat Mono'}
                fontWeight={'bold'}
                lineHeight={1}
                minWidth={'3ch'}
                align={'center'}
                color={'white'}
              >
                {Math.floor(score['speed'])}
              </Text>
              <Text
                color="gray.500"
                align="center"
                fontSize={'sm'}
                lineHeight={1}
                letterSpacing={'-0.02em'}
                fontFamily={'Akkurat Mono'}
              >
                OL/H
              </Text>
            </SimpleGrid>
          </Flex>
        ))}
      </SimpleGrid>
    </SimpleGrid>
  );
};

export default PicksDashboard;
