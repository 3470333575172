import { Container, Text } from '@chakra-ui/react';

import Navbar from '../components/Navbar';

const NotAuthorized = () => {
  return (
    <Navbar>
      <Container textAlign={'center'}>
        <Text fontWeight={'bold'} fontSize={'2xl'}>
          {' '}
          Not Authorized. Please ask Tormod for access
        </Text>
      </Container>
    </Navbar>
  );
};

export default NotAuthorized;
