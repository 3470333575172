import {
  Box,
  Text,
  Divider,
  useColorModeValue,
  Stack,
  List,
  Link,
  Button
} from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons';

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast
} from '@chakra-ui/react';

import { InformationListItem } from './DataPresentation';

const ShopAccordion = ({ shop, onExport }: any) => {
  const subSubHeaderColor = useColorModeValue('gray.500', 'gray.400');
  const secondaryColor = useColorModeValue('gray.900', 'yellow.100');
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {shop.name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Stack spacing={3}>
          <List>
            <InformationListItem
              label={'Created at'}
              value={shop.created_at.split('T')[0]}
              tooltipText="The time at which the shop was created"
            />
            <InformationListItem
              label={'Allow partial fulfilments'}
              value={
                shop.shop_settings?.allow_partial_fulfillments ? 'Yes' : 'No'
              }
              tooltipText="Whether the shop allows partial fulfilments"
            />
            <InformationListItem
              label={'Shop id'}
              value={shop.id}
              tooltipText="The id of the shop"
              opacity={0.5}
            />
            <Link onClick={() => onExport(shop.name, shop.id)}>
              <Button color={secondaryColor} variant="outline" size="md" ml={2}>
                Get inventory report
                <ExternalLinkIcon mx="2px" />
              </Button>
            </Link>
          </List>
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default ShopAccordion;
