import { useState, useEffect } from 'react';

import {
  Box,
  Heading,
  HStack,
  Text,
  VStack,
  Spinner,
  Container,
  Stack,
  List,
  ListItem,
  StackDivider,
  SimpleGrid,
  Divider,
  Accordion
} from '@chakra-ui/react';

import { useColorModeValue } from '@chakra-ui/react';

import useAxios from '../utils/useAxios';
import TaskInfoAccordionItem from '../components/TaskInfo';
import { InformationListItem } from './DataPresentation';
import OrderLineItemAccordionItem from './OrderLineItemAccordionItem';

const OrderDetails = ({ order }: any) => {
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Colors
  const secondaryColor = useColorModeValue('gray.900', 'yellow.100');
  const stackDividerColor = useColorModeValue('gray.200', 'gray.600');
  const subHeaderColor = useColorModeValue('gray.900', 'gray.100');
  const subSubHeaderColor = useColorModeValue('gray.500', 'gray.400');

  const axios = useAxios();

  useEffect(() => {
    const getOrderDetails = async () => {
      setIsLoading(true);
      const response = await axios.get(`/orders/extended/${order}`);
      setOrderDetails(response.data);
      console.log(response.data);
      setIsLoading(false);
    };
    if (order) {
      getOrderDetails();
    }
  }, [order]);

  return (
    <Container maxW="100%">
      {isLoading ? (
        <Spinner />
      ) : (
        <Stack spacing={{ base: 6, md: 10 }}>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={'column'}
            divider={<StackDivider borderColor={stackDividerColor} />}
          >
            <Text color={subHeaderColor} fontSize={'2xl'} fontWeight={'400'}>
              Order name: {orderDetails?.order.order_name}
            </Text>

            {orderDetails !== null && (
              <SimpleGrid
                minChildWidth={{ base: '100%', md: '40%' }}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 18, md: 10 }}
              >
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Information
                  </Text>
                  <List spacing={2}>
                    <InformationListItem
                      label="Shop"
                      value={orderDetails.shop?.name}
                      tooltipText="The shop where the order was placed"
                    />
                    <InformationListItem
                      label="Organization"
                      value={orderDetails.organization?.name}
                      tooltipText="The organization the order was placed in"
                    />
                    <InformationListItem
                      label="Status"
                      value={orderDetails.order?.status}
                      tooltipText="The status of the order in PIO"
                    />
                    <InformationListItem
                      label="Has deviations"
                      value={
                        orderDetails.deviations?.length > 0
                          ? orderDetails.deviations?.map(
                              (deviation: any) => deviation.reason
                            )
                          : 'No'
                      }
                      tooltipText="If defined, the order has deviations"
                    />
                    <InformationListItem
                      label="Is unfullfillable"
                      value={
                        orderDetails.order?.is_unfulfillable ? 'Yes' : 'No'
                      }
                      tooltipText="The order is unfullfillable"
                    />
                    <InformationListItem
                      label="Is partially fullfillable"
                      value={
                        orderDetails.order?.is_partially_fulfillable
                          ? 'Yes'
                          : 'No'
                      }
                      tooltipText="The order is partially fullfillable"
                    />
                    <InformationListItem
                      label="Created at"
                      value={orderDetails.order?.created_at}
                      opacity="50%"
                      tooltipText="The date and time the order was created"
                    />
                    <InformationListItem
                      label="Updated at"
                      value={orderDetails.order?.updated_at}
                      opacity="50%"
                      tooltipText="The date and time the order was last updated"
                    />
                    <InformationListItem
                      label="Cancelled at"
                      value={orderDetails.order?.cancelled_at}
                      opacity="50%"
                      tooltipText="If defined, the order was cancelled"
                    />
                    <InformationListItem
                      label="PIO order id"
                      value={orderDetails.order?.id}
                      opacity="50%"
                      tooltipText="DEBUG: The id of the order in PIO"
                    />
                    <InformationListItem
                      label="External order id"
                      value={orderDetails.order?.ext_order_id}
                      opacity="50%"
                      tooltipText="DEBUG: The id of the order in the external system"
                    />
                    <InformationListItem
                      label="Tags"
                      value={orderDetails.tags}
                      opacity="50%"
                      tooltipText="Tags set on the order"
                    />
                  </List>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Fulfillments
                  </Text>
                  {orderDetails.fulfillments?.map((fulfillment: any) => (
                    <Box>
                      <Heading
                        as={'h5'}
                        size={'md'}
                        fontWeight={'300'}
                        pb="1"
                        color={subSubHeaderColor}
                      >
                        Fulfillment {fulfillment.name}
                      </Heading>
                      <Divider maxW={'75%'} />
                      <List spacing={2} py={'3'}>
                        <InformationListItem
                          label="Fulfillment name"
                          value={fulfillment.name}
                          tooltipText="The name of the fulfillment"
                        />
                        <InformationListItem
                          label="Fulfillment status"
                          value={fulfillment.status}
                          tooltipText="The status of the fulfillment"
                        />
                        <InformationListItem
                          label="Has deviation"
                          value={fulfillment.deviated_at ? 'Yes' : 'No'}
                          tooltipText="If the fulfillment has deviations"
                        />
                        <InformationListItem
                          label="Fulfillment id"
                          value={fulfillment.id}
                          opacity="50%"
                          tooltipText="DEBUG: The id of the fulfillment in PIO"
                        />
                      </List>
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Shipments
                  </Text>
                  {orderDetails.shipments?.map((shipment: any) => (
                    <Box>
                      <Heading
                        as={'h5'}
                        size={'md'}
                        fontWeight={'300'}
                        pb="1"
                        color={subSubHeaderColor}
                      >
                        Shipment number {shipment.id}
                      </Heading>
                      <Divider maxW={'75%'} />
                      <List spacing={2} py={'3'}>
                        <InformationListItem
                          label="Shipment status"
                          value={shipment.status}
                          tooltipText="The status of the shipment"
                        />
                        <InformationListItem
                          label="Ext. shipment status"
                          value={shipment.ext_status}
                          tooltipText="The status of the shipment at the shipping provider (i.e. DHL)"
                        />
                        <InformationListItem
                          label="Error description"
                          value={shipment.error_description}
                          tooltipText="If the shipment has an error, this is the description of the error"
                        />
                        <InformationListItem
                          label="Shipment id"
                          value={shipment.id}
                          opacity="50%"
                          tooltipText="DEBUG: The id of the shipment in PIO"
                        />
                        <InformationListItem
                          label="Ext. shipment id"
                          value={shipment.ext_shipment_id}
                          opacity="50%"
                          tooltipText="DEBUG: The id of the shipment at the shipping provider (i.e. DHL)"
                        />
                      </List>
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Shipping provider info
                  </Text>
                  {orderDetails.shipment_orders?.map(
                    (shipment_order_line: any) => (
                      <Box>
                        <Heading
                          as={'h5'}
                          size={'md'}
                          fontWeight={'300'}
                          pb="1"
                          color={subSubHeaderColor}
                        >
                          Shipment order number {shipment_order_line.id}
                        </Heading>
                        <Divider maxW={'75%'} />
                        <List spacing={2} pt={'3'}>
                          <InformationListItem
                            label="Order name"
                            value={shipment_order_line.shipment_order_name}
                            tooltipText="The name of the shipment order"
                          />
                          <InformationListItem
                            label="Quantity"
                            value={shipment_order_line.quantity}
                            tooltipText="The quantity of items in the shipment order (?)"
                          />
                          <InformationListItem
                            label="Ext. status"
                            value={shipment_order_line.ext_status}
                            tooltipText="The status of the shipment order at the shipping provider (i.e. DHL)"
                          />
                          <InformationListItem
                            label="Shipping rate"
                            value={orderDetails.shipping_rate_name}
                            tooltipText="The shipping rate of the shipment order"
                          />
                          <InformationListItem
                            label="Shipment order id"
                            value={shipment_order_line.id}
                            opacity="50%"
                            tooltipText="DEBUG: The id of the shipment order in PIO"
                          />
                          <InformationListItem
                            label="Ext. shipment order id"
                            value={shipment_order_line.ext_shipment_order_id}
                            opacity="50%"
                            tooltipText="DEBUG: The id of the shipment order at the shipping provider (i.e. DHL)"
                          />
                        </List>
                      </Box>
                    )
                  )}
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Tasks
                  </Text>
                  {orderDetails?.tasks?.map((task: any) => (
                    <Box>
                      <Accordion allowMultiple>
                        <TaskInfoAccordionItem task={task} />
                      </Accordion>
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Order line items
                  </Text>
                  {orderDetails?.order_line_items?.map(
                    (order_line_item: any) => (
                      <Box>
                        <Accordion allowMultiple>
                          <OrderLineItemAccordionItem
                            order_line_item={order_line_item}
                            order_details={orderDetails}
                          />
                        </Accordion>
                      </Box>
                    )
                  )}
                </Box>
              </SimpleGrid>
            )}
          </Stack>
        </Stack>
      )}
    </Container>
  );
};

export default OrderDetails;
