import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import {
  Heading,
  VStack,
  StackDivider,
  Input,
  Button,
  HStack,
  Box,
  Link
} from '@chakra-ui/react';

import Navbar from '../components/Navbar';
import useAxios from '../utils/useAxios';
import { DataTable } from '../components/DataTable';
import { useColorModeValue } from '@chakra-ui/react';

import OrderDetails from '../components/OrderDetail';
import OrganizationSelect from '../components/OrganizationSelect';

const OrderSearchResultTable = ({ results }: any) => {
  type SearchResult = {
    shop_name: string;
    organization_name: string;
    order_name: string;
    created_at: string;
    status: string;
    id: number;
  };

  const data: SearchResult[] = results;

  const columnHelper = createColumnHelper<SearchResult>();

  const columns = [
    columnHelper.accessor('order_name', {
      cell: info => info.getValue(),
      header: 'Order name'
    }),
    columnHelper.accessor('shop_name', {
      cell: info => info.getValue(),
      header: 'Shop name'
    }),
    columnHelper.accessor('organization_name', {
      cell: info => info.getValue(),
      header: 'Organization name'
    }),
    columnHelper.accessor('created_at', {
      cell: info => info.getValue(),
      header: 'Created at',
      meta: {
        isDate: true
      }
    }),
    columnHelper.accessor('status', {
      cell: info => info.getValue(),
      header: 'Status'
    }),
    columnHelper.accessor('id', {
      cell: id => (
        <Link href={`orders?order-id=${id.getValue()}`}>
          <Button size="sm">View</Button>
        </Link>
      ),
      header: 'Actions'
    })
  ];

  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderColor={useColorModeValue('gray.200', 'gray.900')}
      borderWidth="1px"
      rounded="md"
    >
      {data.length > 0 ? (
        <DataTable columns={columns} data={data} />
      ) : (
        <Box p="4">No results found</Box>
      )}
    </Box>
  );
};

const OrderSearch = ({
  setResults,
  setResultsVisible,
  setOrderViewVisible
}: any) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [searching, setSearching] = useState(false);
  const api = useAxios();

  const handleSearch = async (e: any) => {
    e.preventDefault();
    setSearching(true);
    setOrderViewVisible(false);
    console.log(selectedOrganization);
    const response = await api.get('/orders/search', {
      params: {
        order_name: searchTerm,
        ...(selectedOrganization
          ? { organization_id: selectedOrganization }
          : {})
      }
    });
    setResults(response.data.orders);
    setResultsVisible(true);
    setSearching(false);
  };

  return (
    <HStack as={'form'}>
      <Box width="40%">
        <OrganizationSelect
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
        />
      </Box>
      <Input
        width="40%"
        type="text"
        placeholder="Enter order name"
        bg={useColorModeValue('white', 'gray.800')}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <Button
        type="submit"
        isLoading={searching}
        color={useColorModeValue('gray.800', 'yellow.100')}
        bg={useColorModeValue('yellow.100', 'gray.700')}
        onClick={handleSearch}
      >
        Search
      </Button>
    </HStack>
  );
};

const Orders = () => {
  const [orderSearchResults, setOrderSearchResults] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState<number | null>(null);
  const [orderSearchResultsVisible, setOrderSearchResultsVisible] =
    useState(false);
  const [orderViewVisible, setOrderViewVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const orderId = searchParams.get('order-id');

  useEffect(() => {
    if (orderId) {
      setOrderViewVisible(true);
      setOrderSearchResultsVisible(false);
      setSelectedOrder(+orderId);
    }
  }, [orderId]);

  return (
    <Navbar>
      <VStack
        spacing={6}
        align="stretch"
        divider={
          <StackDivider
            borderColor={useColorModeValue('gray.200', 'gray.600')}
          />
        }
      >
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          color={useColorModeValue('gray.900', 'yellow.100')}
          fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
        >
          Find order
        </Heading>
        <OrderSearch
          setResults={setOrderSearchResults}
          setResultsVisible={setOrderSearchResultsVisible}
          setOrderViewVisible={setOrderViewVisible}
        />
        {orderSearchResultsVisible && (
          <OrderSearchResultTable results={orderSearchResults} />
        )}
        {orderViewVisible && <OrderDetails order={selectedOrder} />}
      </VStack>
    </Navbar>
  );
};

export default Orders;
