import {
  Text,
  Flex,
  Heading,
  Box,
  Container,
  Spacer,
  VStack,
  StackDivider,
  SimpleGrid,
  LinkBox,
  LinkOverlay,
  HStack,
  Skeleton,
  useToast,
  Input,
  Button
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { FiWifi, FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import { BiCube } from 'react-icons/bi';
import { GiSpeedometer } from 'react-icons/gi';
import useAxios from '../utils/useAxios';
import Navbar from '../components/Navbar';
import OrganizationSelect from '../components/OrganizationSelect';

const CreateOrganization = () => {
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const toast = useToast();
  const axios = useAxios();

  const subHeaderColor = useColorModeValue('gray.500', 'gray.400');

  const getOrganizations = async () => {
    await axios
      .get('/organizations')
      .then(res => {
        setOrgs(res.data);
        setLoading(false);
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: err.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const create_organization = (event: any) => {
    axios
      .post('/create-organization/create-organization', {
        organization_name: event.target.name.value
      })
      .then(res => {
        toast({
          title: 'Success',
          description: 'Organization created',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        getOrganizations();
        setSelectedOrganization(res.data.organization.id);
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: err.response.data.detail,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
    event.target.name.value = '';
  };

  const create_admin_user = (event: any) => {
    // Required fields are: first_name, last_name, email, organization_id,

    event.preventDefault();
    axios
      .post('/create-organization/create-admin-user', {
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        email: event.target.email.value,
        organization_id: selectedOrganization
      })
      .then(res => {
        toast({
          title: 'Success',
          description:
            'Admin user created. Remember to request a password reset.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: err.response.data.detail,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      });
    event.target.first_name.value = '';
    event.target.last_name.value = '';
    event.target.email.value = '';
  };

  return (
    <Navbar>
      <Box as={'header'}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          color={useColorModeValue('gray.900', 'yellow.100')}
          fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
        >
          Create new organization
        </Heading>
      </Box>
      <VStack
        divider={
          <StackDivider
            borderColor={useColorModeValue('gray.200', 'gray.600')}
          />
        }
        spacing={4}
        align="stretch"
        mt="10"
      >
        <Box>
          <Text color={subHeaderColor} fontSize={'2xl'} fontWeight={'300'}>
            1. Add organization
          </Text>
          <Flex as="form" onSubmit={create_organization} mt="5">
            <Input
              placeholder="Organization name"
              size="lg"
              mb="5"
              maxW="500"
              name="name"
            />
            <Button size="lg" type="submit" variant="outline">
              Create
            </Button>
          </Flex>
        </Box>
        <Box>
          <Text color={subHeaderColor} fontSize={'2xl'} fontWeight={'300'}>
            2. Add admin user
          </Text>
          <Box as="form" onSubmit={create_admin_user}>
            {/* Required fields are: first_name, last_name, email, organization_id,  */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt="5">
              <Box>
                <Text color={subHeaderColor} fontSize={'xl'} fontWeight={'300'}>
                  First name
                </Text>
                <Input
                  placeholder="First name"
                  size="lg"
                  maxW="500"
                  name="first_name"
                />
              </Box>
              <Box>
                <Text color={subHeaderColor} fontSize={'xl'} fontWeight={'300'}>
                  Last name
                </Text>
                <Input
                  placeholder="Last name"
                  size="lg"
                  maxW="500"
                  name="last_name"
                />
              </Box>
              <Box>
                <Text color={subHeaderColor} fontSize={'xl'} fontWeight={'300'}>
                  Email
                </Text>
                <Input placeholder="Email" size="lg" maxW="500" name="email" />
              </Box>
              <Box maxW="500">
                <Text color={subHeaderColor} fontSize={'xl'} fontWeight={'300'}>
                  Organization
                </Text>
                <OrganizationSelect
                  selectedOrganization={selectedOrganization}
                  setSelectedOrganization={setSelectedOrganization}
                />
              </Box>
              <Box>
                <Button size="lg" type="submit" variant="outline">
                  Create
                </Button>
              </Box>
            </SimpleGrid>
          </Box>
        </Box>
        <Box>
          <Text color={subHeaderColor} fontSize={'2xl'} fontWeight={'300'}>
            3. Stub stuff
          </Text>
        </Box>
      </VStack>
    </Navbar>
  );
};

export default CreateOrganization;
