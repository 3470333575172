import { Container, Text } from '@chakra-ui/react';

import Navbar from '../components/Navbar';

const NotImplemented = () => {
  return (
    <Navbar>
      <Container textAlign={'center'}>
        <Text fontWeight={'bold'} fontSize={'2xl'}>
          {' '}
          This page is not implemented yet.
        </Text>
      </Container>
    </Navbar>
  );
};

export default NotImplemented;
