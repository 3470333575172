import {
  Box,
  Text,
  Heading,
  Divider,
  useColorModeValue,
  List
} from '@chakra-ui/react';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';

import { InformationListItem } from './DataPresentation';

const calcCompletedInTasks = (order_line_item: any, order_details: any) => {
  let completed_in_tasks = 0;
  order_details.tasks.forEach((task: any) => {
    if (task.completed && task.order_line_id === order_line_item.id) {
      completed_in_tasks += task.quantity;
    }
  });
  return completed_in_tasks;
};

const OrderLineItemAccordionItem = ({
  order_line_item,
  order_details
}: any) => {
  const completed_in_tasks = calcCompletedInTasks(
    order_line_item,
    order_details
  );

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box
            flex="1"
            textAlign="left"
            color={
              completed_in_tasks === order_line_item.quantity
                ? 'green.300'
                : 'red.300'
            }
          >
            {order_line_item.product.name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <List spacing={3}>
          <InformationListItem
            label={'SKU'}
            value={order_line_item.product.sku}
            tooltipText="The SKU of the product"
          />
          <InformationListItem
            label={'Quantity'}
            value={order_line_item.quantity}
            tooltipText="The quantity of the product"
          />
          <InformationListItem
            label={'Fullfillable Quantity'}
            value={order_line_item.fulfillable_quantity}
            tooltipText="The quantity of the product that is available to be shipped"
          />
          <InformationListItem
            label={'Quantity in stock'}
            value={order_line_item.product.quantity_in_stock}
            tooltipText="The quantity of the product that is available to be shipped"
          />
          <InformationListItem
            label={'Completed in tasks'}
            value={completed_in_tasks}
            tooltipText="The quantity of the product that has been completed in tasks"
          />
        </List>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default OrderLineItemAccordionItem;
