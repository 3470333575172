import React from 'react';
import {
  Box,
  Text,
  useColorModeValue,
  List,
  Stack,
  StackDivider,
  Center,
  Progress
} from '@chakra-ui/react';
import { InformationListItem } from '../components/DataPresentation';
import useAxios from '../utils/useAxios';
import { useEffect, useState } from 'react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
const PicksDashboard = () => {
  // Colors
  const secondaryColor = useColorModeValue('gray.900', 'yellow.100');
  const subHeaderColor = useColorModeValue('gray.500', 'gray.400');
  const stackDividerColor = useColorModeValue('gray.200', 'gray.600');

  const [scores, setScores] = useState([]);

  const api = useAxios();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/ops/scoreboard');
        setScores(response.data.scores);
      } catch (e) {
        console.log('Error: ', e);
        setScores([]);
      }
    };
    fetchData();
    const id = setInterval(() => fetchData(), 30000);
    return () => clearInterval(id);
  }, []);

  return (
    <Box margin={'5'}>
      <ColorModeSwitcher />
      <Center>
        <Stack
          margin={'-50px 0 0 0'}
          spacing={{ base: 4, sm: 2 }}
          direction={'column'}
        >
          <Text
            color={secondaryColor}
            fontSize={'6xl'}
            fontWeight={'500'}
            textTransform={'uppercase'}
            align={'center'}
            mb={'1'}
          >
            Scoreboard
          </Text>
          {scores.map(score => (
            <Box>
              <Text
                color={secondaryColor}
                fontSize={'l'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                align={'center'}
                mb={'1'}
              >
                {score['username']} - {score['org']}
              </Text>
              <Progress value={(score['speed'] * 100) / 200} />
              {Math.round(score['speed'])} OL/H
            </Box>
          ))}
        </Stack>
      </Center>
    </Box>
  );
};

export default PicksDashboard;
