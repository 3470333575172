import {
  Text,
  Flex,
  Heading,
  Box,
  Container,
  Spacer,
  VStack,
  StackDivider,
  SimpleGrid,
  LinkBox,
  LinkOverlay,
  Skeleton,
  useToast,
  Link
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import useAxios from '../utils/useAxios';
import Navbar from '../components/Navbar';

import style from './DashBoard.module.css';
import {
  FiAlertTriangle,
  FiClock,
  FiCloudOff,
  FiFileText,
  FiGrid,
  FiInfo,
  FiMenu,
  FiPower,
  FiWifi,
  FiWifiOff
} from 'react-icons/fi';

const GetColorFromFillingRate = (fillingRate: number) => {
  const ok_color = useColorModeValue('green.500', 'green.200');
  const warning_color = useColorModeValue('yellow.500', 'yellow.200');
  const critical_color = useColorModeValue('red.500', 'red.200');
  if (fillingRate > 0.99) {
    return critical_color;
  } else if (fillingRate > 0.95) {
    return warning_color;
  } else {
    return ok_color;
  }
};

const OrganizationOverview = ({ organization }: any) => {
  const successColor = useColorModeValue('green.500', 'green.200');
  const errorColor = useColorModeValue('red.500', 'red.200');
  return (
    <LinkBox className={style.panel}>
      <LinkOverlay href={`organization?organization-id=${organization.id}`}>
        <Heading
          color={useColorModeValue('gray.700', 'white')}
          lineHeight={1}
          fontWeight={'normal'}
          fontSize={{ base: 'xl', sm: '2xl', lg: '3xl' }}
          marginBlockEnd={'0.75rem'}
        >
          {organization['name']}
        </Heading>

        <SimpleGrid
          gridTemplateColumns={'repeat(auto-fit, minmax(200px, 1fr))'}
          gap="0.75rem"
        >
          {organization.stub_connected ? (
            <Flex className={style.orgStat}>
              <FiWifi size={20} />
              <Text
                color={successColor}
                fontSize={'l'}
                fontWeight={'300'}
                ml={2}
              >
                Connected
              </Text>
            </Flex>
          ) : (
            <Flex className={style.orgStat}>
              <FiWifiOff size={20} />
              <Text color={errorColor} fontSize={'l'} fontWeight={'300'} ml={2}>
                Disconnected
              </Text>
            </Flex>
          )}
          {organization.stub_connected ? (
            <>
              <Flex className={style.orgStat}>
                <FiPower size={20} />
                {organization.system_mode !== 'RUN' ? (
                  <Text
                    color={errorColor}
                    fontSize={'l'}
                    fontWeight={'300'}
                    ml={2}
                  >
                    Not running ({organization.system_mode})
                  </Text>
                ) : (
                  <Text
                    color={successColor}
                    fontSize={'l'}
                    fontWeight={'300'}
                    ml={2}
                  >
                    Running
                  </Text>
                )}
              </Flex>
              <Flex className={style.orgStat}>
                <FiInfo size={20} />
                {organization.robot_has_error_count ? (
                  <Text
                    color={errorColor}
                    fontSize={'l'}
                    fontWeight={'300'}
                    ml={2}
                  >
                    {organization.robot_has_error_count} robot errors
                  </Text>
                ) : (
                  <Text
                    color={successColor}
                    fontSize={'l'}
                    fontWeight={'300'}
                    ml={2}
                  >
                    No robot errors
                  </Text>
                )}
              </Flex>

              <Flex className={style.orgStat}>
                <FiAlertTriangle size={20} />
                {organization.robot_unavailable_count ? (
                  <Text
                    color={errorColor}
                    fontSize={'l'}
                    fontWeight={'300'}
                    ml={2}
                  >
                    {organization.robot_unavailable_count} unavailable robots
                  </Text>
                ) : (
                  <Text
                    color={successColor}
                    fontSize={'l'}
                    fontWeight={'300'}
                    ml={2}
                  >
                    No unavailable robots
                  </Text>
                )}
              </Flex>
            </>
          ) : (
            <Flex className={style.orgStat}>
              <FiCloudOff size={20} />
              <Text color={errorColor} fontSize={'l'} fontWeight={'300'} ml={2}>
                AS data unavailable
              </Text>
            </Flex>
          )}
          <Flex className={style.orgStat}>
            <FiGrid size={20} />
            <Text
              color={GetColorFromFillingRate(
                organization.used_compartments_ratio
              )}
              fontSize={'l'}
              fontWeight={'300'}
              ml={2}
            >
              {(organization.used_compartments_ratio * 100).toFixed(1)}% comp.
              used
            </Text>
          </Flex>
          <Flex className={style.orgStat}>
            <FiClock size={20} />
            <Text
              color={useColorModeValue('gray.800', 'gray.200')}
              fontSize={'l'}
              fontWeight={'300'}
              ml={2}
            >
              Picks last hour (12h): {organization.tasks_completed_last_hour} (
              {organization.tasks_completed_last_12_hour})
            </Text>
          </Flex>

          <Flex className={style.orgStat}>
            <FiFileText size={20} />
            <Text
              color={useColorModeValue('gray.800', 'gray.200')}
              fontSize={'l'}
              fontWeight={'300'}
              ml={2}
            >
              {organization.orders_total} orders
            </Text>
          </Flex>
          <Flex className={style.orgStat}>
            <FiMenu size={20} />
            <Text
              color={useColorModeValue('gray.800', 'gray.200')}
              fontSize={'l'}
              fontWeight={'300'}
              ml={2}
            >
              {organization.orderlines_total} order lines
            </Text>
          </Flex>
        </SimpleGrid>
      </LinkOverlay>
    </LinkBox>
  );
};

function compare(a: any, b: any) {
  if (a.badness_score > b.badness_score) {
    return -1;
  }
  if (a.badness_score < b.badness_score) {
    return 1;
  }
  return 0;
}

const Dashboard = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const api = useAxios();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/dashboard/summary');
        const sorted_organizations = response.data.organizations.sort(compare);
        setOrganizations(sorted_organizations);
        setLoading(false);
      } catch {
        setOrganizations([]);
        setLoading(false);
        toast({
          title: 'Error',
          description: 'Failed to fetch dashboard data',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    };
    fetchData();
    const id = setInterval(() => fetchData(), 10000);
    return () => clearInterval(id);
  }, []);

  return (
    <Navbar>
      <Container maxW="100%">
        <VStack
          spacing={8}
          align="stretch"
          divider={
            <StackDivider
              borderColor={useColorModeValue('gray.200', 'gray.600')}
            />
          }
        >
          <Flex>
            <Box as={'header'}>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                color={useColorModeValue('gray.900', 'zest.400')}
                fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
              >
                Organizations
              </Heading>
            </Box>
            <Spacer />
            <Box as={'button'}>
              <Link
                href="/create-organization"
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('gray.900', 'zest.400')}
                fontWeight={'500'}
                aria-label="Not implemented yet"
              >
                Create organization
              </Link>
            </Box>
          </Flex>
          <Skeleton isLoaded={!loading}>
            <SimpleGrid
              gap={'1rem'}
              placeContent={'start'}
              templateColumns="repeat(auto-fit, minmax(max(100% / 4 - 0.25rem, min(250px, 100%)), 1fr));"
            >
              {organizations.map(organization => (
                <OrganizationOverview
                  key={organization['id']}
                  organization={organization}
                />
              ))}
            </SimpleGrid>
          </Skeleton>
        </VStack>
      </Container>
    </Navbar>
  );
};

export default Dashboard;
