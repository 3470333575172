import { Select, useColorModeValue } from '@chakra-ui/react';

import { useEffect, useState } from 'react';

import useAxios from '../utils/useAxios';

const OrganizationSelect = ({
  organizations = [],
  selectedOrganization,
  setSelectedOrganization
}: any) => {
  const [localOrganizations, setLocalOrganizations] = useState(organizations);

  const api = useAxios();

  useEffect(() => {
    if (!localOrganizations.length) {
      const fetchData = async () => {
        try {
          const response = await api.get('/organizations');
          setLocalOrganizations(response.data.organizations);
          console.log(localOrganizations);
        } catch {
          setLocalOrganizations([]);
          // Raise error?
        }
      };
      fetchData();
    }
  }, []);

  return (
    <Select
      onChange={e => {
        setSelectedOrganization(e.target.value);
      }}
      placeholder="Select an organization"
      value={selectedOrganization || ''}
      size="lg"
      variant="filled"
      color={useColorModeValue('gray.900', 'yellow.100')}
      bg={useColorModeValue('white', 'gray.700')}
      borderColor="gray.600"
      borderRadius="lg"
      borderWidth="1px"
      _hover={{ borderColor: 'gray.300' }}
      _focus={{ borderColor: 'gray.300' }}
      _disabled={{ opacity: 0.5 }}
      isDisabled={localOrganizations.length === 0}
    >
      {localOrganizations.map((organization: any) => (
        <option key={organization.id} value={organization.id}>
          {organization.name}
        </option>
      ))}
    </Select>
  );
};

export default OrganizationSelect;
