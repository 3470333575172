import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Akkurat';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/AkkuratLLSub-Regular.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Akkurat';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/AkkuratLLSub-Italic.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Akkurat';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/AkkuratLLSub-Bold.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Akkurat';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/AkkuratLLSub-BoldItalic.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Akkurat Mono';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/AkkuratMonoLLSub-Regular.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Akkurat Mono';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/AkkuratMonoLLSub-Italic.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Akkurat Mono';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/AkkuratMonoLLSub-Bold.woff2') format('woff2');
      }

      @font-face {
        font-family: 'Akkurat Mono';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/AkkuratMonoLLSub-BoldItalic.woff2') format('woff2');
      }
      `}
  />
);

export default Fonts;
