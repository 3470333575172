import { Text, Flex, Heading, SimpleGrid, Skeleton } from '@chakra-ui/react';

import style from './OrgsDashboard.module.css';

import { useEffect, useState } from 'react';
import { useColorModeValue, useColorMode } from '@chakra-ui/react';
import useAxios from '../utils/useAxios';

import SpeedGauge from '../components/SpeedGauge/SpeedGauge';
import ActivityMeter from '../components/ActivityMeter/ActivityMeter';
import { FiAlignLeft, FiCalendar, FiClock, FiGrid } from 'react-icons/fi';

const GetColorFromFillingRate = (fillingRate: number) => {
  const ok_color = 'var(--chakra-colors-green-300)';
  const warning_color = 'var(--chakra-colors-yellow-300)';
  const critical_color = 'var(--chakra-colors-red-300)';
  if (fillingRate > 0.99) {
    return critical_color;
  } else if (fillingRate > 0.95) {
    return warning_color;
  } else {
    return ok_color;
  }
};

const OrganizationOverview = ({ organization }: any) => {
  return (
    <SimpleGrid
      className={`${style.org} ${organization.stub_connected === false ? style.disconnected : ''}`}
    >
      <Heading
        color={useColorModeValue('gray.700', 'white')}
        lineHeight={1}
        fontWeight={500}
        fontSize={'2rem'}
        letterSpacing={'-0.04em'}
        flexGrow={1}
      >
        {organization['name']}
      </Heading>

      <ActivityMeter data={organization.activities} />

      <SimpleGrid
        flexGrow={0}
        gridTemplateColumns={'auto auto 1fr'}
        gridTemplateRows={'1fr 1fr'}
        gap={1}
      >
        <Flex
          className={`${style.metric} ${organization.stub_connected == null ? style.inactive : ''}`}
        >
          <FiGrid size={18} style={{ color: 'var(--chakra-colors-gray-400' }} />
          <Text
            className={style.metricLabel}
            style={
              {
                '--color':
                  organization.stub_connected !== null
                    ? GetColorFromFillingRate(
                        organization.used_compartments_ratio
                      )
                    : ''
              } as React.CSSProperties
            }
          >
            {organization.stub_connected !== null
              ? (organization.used_compartments_ratio * 100).toFixed(1) + '%'
              : '0'}
          </Text>
        </Flex>

        <Flex className={style.metric}>
          <FiClock
            size={18}
            style={{ color: 'var(--chakra-colors-gray-400' }}
          />
          <Text className={style.metricLabel}>
            {organization.tasks_completed_last_hour}
          </Text>
        </Flex>

        <Flex className={style.metric}>
          <FiAlignLeft
            size={18}
            style={{ color: 'var(--chakra-colors-gray-400' }}
          />
          <Text className={style.metricLabel}>
            {organization.orderlines_total}
          </Text>
        </Flex>

        <Flex className={style.metric}>
          <FiCalendar
            size={18}
            style={{ color: 'var(--chakra-colors-gray-400' }}
          />
          <Text className={style.metricLabel}>
            {organization.tasks_completed_all_day}
          </Text>
        </Flex>

        <SpeedGauge
          speed={organization.avg_orderlines_per_hour}
          max_speed="200"
        />
      </SimpleGrid>
    </SimpleGrid>
  );
};

const OrgsDashboard = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useAxios();
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('dark');
    const fetchData = async () => {
      try {
        const response = await api.get('/ops/summary');
        setOrganizations(response.data.organizations);
        setLoading(false);
      } catch (error) {
        console.log('error: ' + error);
        setOrganizations([]);
        setLoading(false);
      }
    };
    fetchData();
    const id = setInterval(() => fetchData(), 30000);
    return () => clearInterval(id);
  }, []);

  return (
    <Skeleton isLoaded={!loading}>
      <SimpleGrid
        gridTemplateRows={'1fr auto'}
        bgColor={'gray.1000'}
        minHeight={'100vh'}
        p={'0.25rem'}
        gap="0.25rem"
      >
        <SimpleGrid
          gap={'0.25rem'}
          placeContent={'start'}
          templateColumns="repeat(auto-fit, minmax(max(100% / 10 - 0.25rem, min(300px, 100%)), 1fr));"
          position={'relative'}
        >
          {organizations.map(organization => (
            <OrganizationOverview
              key={organization['id']}
              organization={organization}
            />
          ))}
        </SimpleGrid>
        <Flex className={style.legend}>
          <Flex gap={1} alignItems={'center'}>
            <FiGrid size={16} color={'#79818D'} />
            <Text
              fontSize={'xs'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              lineHeight={1}
              letterSpacing={'-0.04em'}
            >
              Compartments in use
            </Text>
          </Flex>
          <Flex gap={1} alignItems={'center'}>
            <FiAlignLeft size={16} color={'#79818D'} />
            <Text
              fontSize={'xs'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              lineHeight={1}
              letterSpacing={'-0.04em'}
            >
              Order lines
            </Text>
          </Flex>
          <Flex gap={1} alignItems={'center'}>
            <FiClock size={16} color={'#79818D'} />
            <Text
              fontSize={'xs'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              lineHeight={1}
              letterSpacing={'-0.04em'}
            >
              Picks last hour
            </Text>
          </Flex>
          <Flex gap={1} alignItems={'center'}>
            <FiCalendar size={16} color={'#79818D'} />
            <Text
              fontSize={'xs'}
              fontFamily={'Akkurat Mono'}
              textTransform={'uppercase'}
              lineHeight={1}
              letterSpacing={'-0.04em'}
            >
              Picks today
            </Text>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Skeleton>
  );
};

export default OrgsDashboard;
