import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useState } from 'react';

const baseURL = process.env.REACT_APP_API_URL;

const useAxios = (noAuth: boolean = false) => {
  // Accuire token silently
  const { instance, accounts } = useMsal();
  const [token, setToken] = useState<string | null>(null);

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  axiosInstance.interceptors.request.use(async (req: any) => {
    const request = {
      ...loginRequest,
      account: accounts[0],
      scopes: [process.env.REACT_APP_API_SCOPE ?? '']
    };
    if (!noAuth) {
      let response = await instance.acquireTokenSilent(request);
      setToken(response.accessToken);
      req.headers.Authorization = `Bearer ${response.accessToken}`;
    }
    return req;
  });
  return axiosInstance;
};

export default useAxios;
