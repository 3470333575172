import { ListItem, Text, Tooltip } from '@chakra-ui/react';

export const InformationListItem = ({
  label,
  value,
  tooltipText = '',
  ...props
}: any) => {
  return (
    <Tooltip isDisabled={!tooltipText} label={tooltipText}>
      <ListItem {...props}>
        <Text as={'span'} fontWeight={'bold'}>
          {label}:
        </Text>{' '}
        {value}
      </ListItem>
    </Tooltip>
  );
};
